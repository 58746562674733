<template>
  <section name="info" id="info">
    <div class="bg-dark text-secondary px-4 py-5 text-center">
      <div class="py-5">
        <h1 class="display-5 fw-bold text-white">Generator Edi++</h1>
        <div class="col-lg-6 mx-auto">
          <p class="fs-5 mb-4">Witaj w edytorze dodawania nowych produktów do Nexo. Dodaj produkty i wygeneruj plik edi++.</p>
        </div>
      </div>
    </div>
  </section>

</template>
 
 
 <script>
 export default {
   name : "upper-content",
     components: {
 
   },
 };
 </script>