<template>
  <layout-site></layout-site>
</template>


<style lang="scss">

</style>

<script>

import LayoutSite from "@/components/LayoutSite.vue";

export default {
  name: "App",
  components: {
    LayoutSite
  },
  data() {
  },
  methods: {
  },
  beforeUpdate() {
  },
  beforeMount() {
  },
};
</script>