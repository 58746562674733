<template>

  <div class="container mt-4 mb-4">

    <button type="button" 
    class="btn btn-outline-info btn-lg px-4 me-sm-3 fw-bold m-4"
      @click="show_form=true"
      v-show="!show_form"
    >Dodaj produkt</button>


    <form v-show="show_form">

      <div class="row ">
        <div class="col-sm-6">
          <label class="form-label text-danger">Symbol*</label>
          <input type="text" class="form-control" placeholder="" required=""
          v-model="symbol"
          :class="error_info_symbol==''?'':'error_valid'"
          @change="clearAlertInfo('symbol')"
          >
          <small class="text-danger">{{ error_info_symbol }}</small>
        </div>

        <div class="col-sm-6">
          <label for="lastName" class="form-label text-danger">Nazwa*</label>
          <input type="text" class="form-control" id="lastName" placeholder="" required=""
          v-model="name"
          :class="error_info_name==''?'':'error_valid'"
          @change="clearAlertInfo('name')"
          >
          <small class="text-danger">{{ error_info_name }}</small>
        </div>

        <div class="col-sm-6">
          <label  class="form-label">Vat</label>
          <select class="form-control" v-model="tax">
            <option value="0">0</option>
            <option value="5">5</option>
            <option value="8">8</option>
            <option value="23">23</option>
          </select>
        </div>

        <div class="col-sm-6">
          <label  class="form-label">Uwagi</label>
          <input type="text" class="form-control" placeholder=""
          v-model="comments"
          >
        </div>

        <div class="col-sm-12">
          <label class="form-label">Opis</label>
          <input type="text" class="form-control"  placeholder=""
          v-model="description"
          >
          <div class="invalid-feedback">
            description
          </div>
        </div>

        <!--
          <div class="col-sm-6">
            <label class="form-label">Ilosc</label>
            <input type="number" step="1" class="form-control" placeholder=""
            v-model="quantity"
            @change="setInt()"
            >
          </div>
        -->

      </div>

      <div class="row">
        <div class="col-6">
          <button class="w-100 btn btn-primary btn-lg mt-2" type="button"
            @click="AddToList()"
          >Dodaj do listy</button>
        </div>
        <div class="col-6">
          <button class="w-100 btn btn-danger btn-lg mt-2" type="button"
            @click="HideForm()"
          >Ukryj Formularz</button>
        </div>

      </div>

    </form>


  </div>
</template>

<style>
.error_valid{
  border: 1px red solid;
}
</style>

<script>


export default {
  name: "product-create", 
  props: {
    item: Array,
  },
  data() {
      return {
        name: '', 
        symbol: '', 
        quantity: '',
        tax: 23,
        description : '',
        comments: '',
        show_form: false,
        localItem: this.item,
        error_info_name: '',
        error_info_symbol: '',
      }
    },
    components: {

    },
    beforeMount() {
    },
    methods: {
      AddToList() {
        let valid = true;
        if(this.symbol === '' ){
          valid = false;
          this.error_info_symbol = 'Dodaj symbol';
        }
        if(this.name === '' ){
          valid = false;
          this.error_info_name= 'Dodaj nazwę';
        }
        
        if(this.symbol.length > 20 ){
          valid = false;
          this.error_info_symbol = 'Symbol może mieć maksymalnie 20 znaków';
        }

        const addedElement = this.item.findIndex(i => i['symbol'] === this.symbol);
        if (valid){
          if (addedElement === -1 ){
            let new_item = Array()
            new_item['name'] = this.name;
            new_item['symbol'] = this.symbol;
            if (this.quantity === ''){
              this.quantity = 0
            }
            new_item['quantity'] = this.quantity;
            new_item['tax'] = this.tax;
            new_item['description'] = this.description;
            new_item['comments'] = this.comments;

            this.ClearItem()

            this.localItem.push(new_item)
          } else {
            this.error_info_symbol = 'Już dodano produkt o tym symbolu ';
          }
        }

      },
      clearAlertInfo(type){
        if (type === 'name'){
          this.error_info_name= ''
        } 
        if (type === 'symbol'){
          this.error_info_symbol = ''
        } 
      },
      setInt(){
        this.quantity= parseInt(this.quantity)
      },
      ClearItem(){
        this.name = "";
        this.symbol = "";
        this.quantity = "";
        this.tax = 23;
        this.description = "";
        this.comments = "";
      },
      HideForm(){
        this.show_form = false;
      }
    }
};
</script>