<template>
  <footer class="site-footer footer-full" style="display: block">
    <div
      class="p-tb60 bg-black"
      style="
        background-image: url(https://www.multiprojekt.pl/wp-content/themes/mp/assets/images/background/bg2.png);
        background-size: contain;
      "
    >
      <div class="container">
        <div class="row">
          <div class="col-md-6 text-md-left text-center footer-logo">
            <a href="/"
              ><img
                src="https://www.multiprojekt.pl/wp-content/themes/mp/assets/images/logo/MULTIPROJEKT_SYGNET_WHITE.png"
                alt="Multiprojekt"
                style="height: 140px"
            /></a>
          </div>
          <div class="col-md-6 text-md-right text-center">
            
            <ul class="list-inline mt-4 m-t10">
              <li>
                <a
                  rel="nofollow"
                  href="https://pl-pl.facebook.com/multiprojekt"
                  class="site-button-link facebook hover  text-white"
                  >Facebook</a>
              </li>
              <li>
                <a
                  rel="nofollow"
                  href="https://www.youtube.com/channel/UCWnhwiiR6IgUiUCDyuV9VeA"
                  class="site-button-link youtube hover  text-white"
                  >Youtube</a>
              </li>
              <li>
                <a
                  rel="nofollow"
                  href="https://www.linkedin.com/company/multiprojekt/"
                  class="site-button-link linkedin hover  text-white"
                  >Linkedin</a>
              </li>
              <li>
                <a
                  rel="nofollow"
                  href="https://www.instagram.com/multiprojekt.pl/"
                  class="site-button-link instagram hover  text-white"
                  >Instagram</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div
            class="col-md-12 text-md-right text-center"
            style="border-top: 1px solid #f0ce43; margin-top: 10px; margin-bottom: 10px; padding-top: 5px;"
          >
            <p class="text-center text-white">
              <b>Multiprojekt Automatyka sp. z o.o.</b> ul. Pilotów 2E, 31-462
              Kraków; NIP: 675-144-15-70 REGON: 121401320;<br />
              Wysokość kapitału zakładowego 170 000 PLN; KRS nr 0000371639 Sąd
              Rejonowy dla Krakowa Śródmieścia w Krakowie.
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "under-content",
  components: {},
};
</script>
