<template>
    <navbar-site />
    <upper-content></upper-content>
    <products-content></products-content>
    <under-content></under-content>
    <footer-content></footer-content>
  </template>
  
  <script>
  import UpperContent from "@/components/template/UpperContent.vue";
  import UnderContent from "@/components/template/UnderContent.vue";
  import FooterContent from "@/components/template/FooterContent.vue";
  import NavbarSite from "@/components/template/NavbarSite.vue";
  import ProductsContent from "@/components/ProductsContent.vue";
  
  
  export default {
    name: "layout-site",
    data() {
      return {
      };
    },
    methods: {
    },
    components: {
      UpperContent,
      UnderContent,
      NavbarSite,
      FooterContent,
      ProductsContent,
    },
    created() {

    },
    beforeMount() {

    },
  };
  </script>