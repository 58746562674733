<template>
  <div class="container mt-4 mb-4">

    <div class="table-responsive">
      <table class="table table-striped table-sm">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Symbol</th>
            <th scope="col">Nazwa</th>
            <th scope="col">Ilość</th>
            <th scope="col">Vat</th>
            <th scope="col">Usuń</th>
          </tr>
        </thead>
        <tbody v-if="localItem.length  >= 1">

          <template v-for="(i, index) in item" v-bind:key="i['symbol']">

            <tr>
              <td>{{index+1}}</td>
              <td>{{ i['symbol'] }}</td>
              <td>{{ i['name'] }}</td>
              <td>{{ i['quantity'] }}</td>
              <td>{{ i['tax'] }}</td>
              <td>
                <button class="btn btn-danger px-2 py-1"
                @click="removeFormList(index)"
                >x</button>
              </td>
            </tr>
            <tr>
              <td  colspan="6">
                    <div v-if="i['comments'] != ''">
                    <label>Uwagi: {{ i['comments'] }}</label>
                </div>
                <div v-if="i['description'] != ''">
                      <label>Opis: {{ i['description'] }}</label>
                </div>
              </td>
            </tr>

          </template>
          
        </tbody>
        <tbody v-else>
          <tr  >
            <td colspan="6">
            <div class="text-danger text-center">Brak dodnych produktów</div>
            </td>
          </tr>
        </tbody>

      </table>
    </div>
  </div>


</template>

<script>
  export default {
    name: "product-list",
    props: {
      item: Array,
    },  
    data() {
      return {
        localItem: this.item,
      }
    },
    components: {

    },
    beforeMount() {
    },
    methods: {
      removeFormList(indexToRemove) {
        this.localItem.splice(indexToRemove, 1);
      }
    }
  };
</script>