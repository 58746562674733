<template>
  <header>
    <div class="px-3 py-2 bg-dark text-white">
      <div class="container">
        <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
          <a href="/" class="d-flex align-items-center my-2 my-lg-0 me-lg-auto text-white text-decoration-none">
            <svg class="bi me-2" width="40" height="32" role="img" aria-label="Bootstrap"><use xlink:href="#bootstrap"></use></svg>
          </a>

          <ul class="nav col-12 col-lg-auto my-2 justify-content-center my-md-0 text-small">

            <li>
              <a href="#home" id="home" class="nav-link text-white">
                Home
              </a>
            </li>
            <li>
              <a href="#product_list" id="product_list"  class="nav-link text-white">
                Dodaj produkt
              </a>
            </li>
            <li>
              <a href="#info" id="info"  class="nav-link text-white">
                Info
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="px-3 py-2 border-bottom mb-3">
      <div class="container d-flex flex-wrap justify-content-center">
        <div class="col-12 col-lg-auto mb-2 mb-lg-0 me-lg-auto">
         
        </div>

        <div class="text-end">
          <a href="https://multiprojekt.pl" target="_balank" class="btn btn-warning">Multiprojekt</a>
        </div>
      </div>
    </div>
  </header>
 </template>
 
 
 <script>
 export default {
   name : "navbar-site",
     components: {
 
   },
 };
 </script>