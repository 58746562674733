import iconv from 'iconv-lite';

export const generateFileServices = {
  generateFileEDI,
};

function generateFileEDI(item){

  let content = '[INFO]\n'
  content += '"1.11",3,1250,"Nexo","100001","MULTIPROJEKT AUTOMATYKA SPÓŁKA Z OGRANIC","MULTIPROJEKT AUTOMATYKA SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ","Kraków","31-462","ul. Pilotów 2E","6751441570","MAGAZYN GŁÓWNY","Magazyn główny MP11",,,0,,,,20230731000000,"Polska","PL","PL6751441570",1\n'
  content += '\n\n'
  content += '[NAGLOWEK]\n'
  content += '"TOWARY"\n\n'
  content += '[ZAWARTOSC]\n'

  item.forEach(i => {
    content += '1,"'+i['symbol']+'",,,"'+i['name']+'","'+i['description']+'",,,,"szt","'+i['tax']+'",'+i['tax']+'.0000,"'+i['tax']+'",'+i['tax']+'.0000,0.0000,0.0000,"szt",0,"PLN",,"szt",0.0000,0.0000,,,0,,0.0000,0.0000,,0,"'+i['comments']+'",0,0,"'+i['tax']+'",,,,,,,\n'
  });

  content += '\n\n'
  content += '[NAGLOWEK]\n'
  content += '"CENNIK"\n'
  content += '\n'
  content += '[ZAWARTOSC]\n'
  content += '\n'
  content += '[NAGLOWEK]\n'
  content += '"GRUPYTOWAROW"\n\n'
  content += '\n'
  content += '[ZAWARTOSC]\n'
  content += '\n\n'
  content += '[NAGLOWEK]\n'
  content += '"DODATKOWETOWAROW"\n\n'
  content += '\n'
  content += '[ZAWARTOSC]\n'
  content += '\n\n'
  content += '[NAGLOWEK]\n'
  content += '"TOWARYGRUPYJPKVAT"\n\n'
  content += '\n'
  content += '[ZAWARTOSC]\n'
  content += '\n'
  content += '[NAGLOWEK]\n'
  content += '"TOWARYKODYCN"\n\n'
  content += '\n'
  content += '[ZAWARTOSC]\n'
  content += '\n'

  const filename = 'edi_product_list.epp';
  const windows1250Buffer = iconv.encode(content, 'win1250');
  const blob = new Blob([windows1250Buffer], {  type: 'text/plain;charset=windows-1250' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  URL.revokeObjectURL(url);
  document.body.removeChild(link);
}
