<template>
  <section id="product_list">
    <product-create :item=item ></product-create>
    <product-list :item=item></product-list>
    <center>
      <button type="button" class="btn btn-outline-warning btn-lg px-4 me-sm-3 fw-bold m-4"
      v-if="item.length >=1 "
      @click="generateFile()"
      >Generuj EDI++</button>
    </center>
  </section>
</template>



<script>
import ProductCreate from "@/components/products/ProductCreate.vue";
import ProductList from "@/components/products/ProductList.vue";
import { generateFileServices } from '@/components/services/generateFileServices.js'
//import iconv from 'iconv-lite';

export default {
    name: "product-content",
    components: {
      ProductList,
      ProductCreate
    },
    data() {
      return {
        item: Array()
      }
    },

    beforeMount() {
    },
    methods: {
      generateFile() {
        generateFileServices.generateFileEDI(this.item)
      },
    }
  };
</script>