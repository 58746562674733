<template>
  <div class="info">
      
  </div>
</template>


<script>
export default {
  name : "under-content",
    components: {

  },
};
</script>